// extracted by mini-css-extract-plugin
export var container = "style-module--container--mz4H0";
export var containerInner = "style-module--containerInner--jCqFV";
export var containerInnerBg = "style-module--containerInnerBg--kU+29";
export var containerInnerFade = "style-module--containerInnerFade--BFrVK";
export var promo = "style-module--promo--46IO+";
export var promoDescr = "style-module--promoDescr--s6-mD";
export var promoInner = "style-module--promoInner--8Mi90";
export var promoInnerBadge = "style-module--promoInnerBadge--G-O6-";
export var promoBadge = "style-module--promoBadge--sgaQa";
export var promoSoon = "style-module--promoSoon--74qZt";